import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import config from "../config/config.json"

const NotFoundPage = () => {
  if (typeof window !== "undefined") {
    let path = window.location.pathname
    // console.log("path", path)
    let isExists = path.includes("accesstoken")
    if (isExists) {
      path = path.split("/")
      path = path[1].split("=")
      document.cookie = `accesstoken=${path[1]}`
      // Local
      // window.location.replace("http://localhost:8000")
      // console.log(config.siteUrl)
      window.location.replace(config.siteUrl)
      return null
    } else {
      return (
        <>
          <Seo title="404: Not Found" />
          <h1>404: Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </>
      )
    }
  } else return null
}

export default NotFoundPage
